<template>
  <div class="content p-0">
    <div class="login-wrapper">
      <template>
        <loader v-if="isLoaded"
        object="#3370b7" 
        color1="#ffffff" 
        color2="#17fd3d" 
        size="5"
        speed="2" 
        bg="#ffffff" 
        objectbg="#cfcfcf" 
        opacity="80"
          disableScrolling="false"
        name="spinning">
        </loader>
      </template>
      <div class="row m-0">
        <div
          class="col-lg-4 h-100-vh side-banner"
          style="background-image:url('/static/images/bg-login.jpg');background-position: center center; background-size: cover;"
        >
          <div class="content px-4 text-center d-flex justify-content-center h-100">
            <div class="align-self-center">
              <!-- <img
                src="/static/images/logo-white.png"
                width="150"
                class="mb-4"
                alt=""
              > -->
               <h2 class="text-white">
                Thriggle
              </h2>
              <h4 class="text-white">
                Discover great places in world
              </h4>
              <p class="text-white">
                Find awesome bar & restaurant activities near you
              </p>
              
            </div>
          </div>
        </div>
        <div class="col-lg-8 site-content">
          <div class="content">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <div class="text-center mb-4">
                  <h2>Forgot Password?</h2>
                  <p>Enter the Registered Email</p>
                </div>
                <div class="card bg-secondary shadow border-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <form role="form">
                      <div class="form-group mb-3">
                        <div class="input-group input-group-alternative" 
                         v-bind:class="{' input-error' : error.email }">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-envelope" /></span>
                          </div>
                           <input
                            v-model="email"
                            class="form-control  "
                            placeholder="Email"
                            type="email"
                             v-bind:class="{' is-invalid' : error.email }"
                          >
                        </div>
                        <small v-if="error.email" class="text-danger"><b>* {{error.email}}</b></small>
                      </div>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn btn-primary mt-4"
                          @click="forgotPassword"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                    <p class="text-center">
                     <router-link
                        class="font-weight-bold"
                        to="/login"
                        style="display:inline !important;"
                      >
                        LOGIN NOW
                      </router-link>
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {axios} from '../services/http-common';
export default {
 data(){
   return{
     email:'',
      isLoaded:false,
     error:{
       email:null
     }
   }
 },
 methods:{
  forgotPassword(){
    if(this.email){
      this.error.email = null;
       this.isLoaded=true;
        axios
      .post(`${process.env.VUE_APP_API_URL}forgot-password`,{'email':this.email})
      .then(response => {
          if (!response.error) {
            this.isLoaded=false;
            this.$router.push({path: '/login'});
            this.$toast.success("Check You Email For The Updated Password", {
                position: "bottom-right",
                timeout: 2508,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
                top:'300px',
              });
            }
        })
      .catch( error => {
        let message = '';
         this.isLoaded=false;
        if(error.response){
          if(error.response.status && error.response.status == 400)
            message = 'Incorrect Email';
          else
            message = 'Something Went Wrong!';

          this.$toast.error(message, {
            position: "bottom-right",
            timeout: 2508,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
            top:'300px',
          });
			  }
      });
    }
    else{
      this.error.email = 'Email Required';
    }
  
    }
 }
}
</script>
<style scoped>

.input-error{
  border:1px solid rgb(255, 101, 101);
}
</style>
